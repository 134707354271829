$(document).ready(function() {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function(){
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function(e){
		var target = $(this).attr("target"),
			url = $(this).attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    }).click(function(){
		var target = $(this).find("a.js-click-item-link").attr("target"),
			url = $(this).find("a.js-click-item-link").attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    });​



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Scroll-To
    // *
    // * Smooth-Scroll to targets on page
    // *
    $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                scrollTo(target);
            }
        }
    });

    function scrollTo(element) {
        $('html, body').animate({
            scrollTop: element.offset().top - 100
        }, 1000);
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 60; // Distance from Browserbottom where the animation should start

    function fadeInElements(){
        var viewPort = $(window).scrollTop() + $(window).height();

        $(".animateIn:visible").each(function(){
            var elementTop = $(this).offset().top;

            if ((elementTop + offset) <= viewPort) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            }
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * sticky header
    // *
    // *
    $(window).scroll(function() {
        if($(window).scrollTop() >= 40) {
            $(".js-header-sticky").addClass("active");
        }else {
            $(".js-header-sticky").removeClass("active");
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * nav mobile
    // *
    // *
    $(".js-nav-mobile-button").click(function () {
        $(".js-nav-mobile").toggleClass("active");
        $('html, body').toggleClass('freeze');
    })

    $(".js-nav-mobile-toggle").click(function () {
        $(this).toggleClass("open");
        $(this).siblings().slideToggle();
    })

    $(".js-nav-mobile-toggle.active").trigger("click");

    $(window).scroll(function() {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function() {
        if(location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        }else {
            $(this).attr('target','_blank');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * slider
	// *
	// *
	var $introSlider = $(".js-intro-slider");

	if ($introSlider.length){
		$introSlider.each(function(){
			$(this).slick({
				fade: false,
				dots: false,
				infinite: true,
				arrows: true,
                autoplay: false,
                autoplaySpeed: 3500,
				speed: 1000,
				prevArrow: $(".js-intro-slider-nav-prev"),
				nextArrow: $(".js-intro-slider-nav-next")
			});
		});
	}



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * scroll top
    // *
    // *
    $(window).scroll(function() {
        if($(window).scrollTop() >= 300) {
            $(".js-scroll-top").addClass("active");
        }else {
            $(".js-scroll-top").removeClass("active");
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * teaserCarousel
	// *
	// *
    var $teaserCarousel = $(".js-teaser-carousel");

	if ($teaserCarousel.length){
		$teaserCarousel.each(function(){
			$(this).slick({
				fade: false,
				dots: false,
				infinite: true,
				arrows: true,
                autoplay: false,
                autoplaySpeed: 3500,
				speed: 1000,
				prevArrow: $(".js-teaser-carousel-nav-prev"),
				nextArrow: $(".js-teaser-carousel-nav-next"),
                slidesToShow: 3,
                slidesToScroll: 1,
                adaptiveHeight: true,
                responsive: [
                        {
                          breakpoint: 1350,
                          settings: {
                            slidesToShow: 2
                          }
                        },
                        {
                          breakpoint: 1000,
                          settings: {
                            slidesToShow: 1
                          }
                        }
                      ]
			});
		});
	}


    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * galleryCarousel
	// *
	// *
    var $galleryCarousel = $(".js-gallery-carousel");

	if ($galleryCarousel.length){
		$galleryCarousel.each(function(){
			$(this).slick({
				fade: false,
				dots: false,
				infinite: true,
				arrows: true,
                autoplay: false,
                autoplaySpeed: 3500,
				speed: 1000,
				prevArrow: $(".js-gallery-carousel-nav-prev"),
				nextArrow: $(".js-gallery-carousel-nav-next"),
                slidesToShow: 3,
                slidesToScroll: 1,
                adaptiveHeight: true,
                responsive: [
                        {
                          breakpoint: 1200,
                          settings: {
                            slidesToShow: 2,
                          }
                        },
                        {
                          breakpoint: 700,
                          settings: {
                            slidesToShow: 1,
                            adaptiveHeight: false,
                          }
                        }
                      ]
			});
		});
	}




    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * iconTeaserCarousel
	// *
	// *
    var $iconTeaserCarousel = $(".js-icon-teaser-carousel");

	if ($iconTeaserCarousel.length){
		$iconTeaserCarousel.each(function(){
			$(this).slick({
				fade: false,
				dots: false,
				infinite: true,
				arrows: true,
                autoplay: false,
                autoplaySpeed: 3500,
				speed: 1000,
				prevArrow: $(".js-icon-teaser-carousel-nav-prev"),
				nextArrow: $(".js-icon-teaser-carousel-nav-next"),
                slidesToShow: 3,
                slidesToScroll: 1,
                adaptiveHeight: true,
                responsive: [
                        {
                          breakpoint: 1200,
                          settings: {
                            slidesToShow: 2
                          }
                        },
                        {
                          breakpoint: 800,
                          settings: {
                            slidesToShow: 1
                          }
                        }
                      ]
			});
		});
	}



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * slideContent
    // *
    // *
    $("[data-slide]").click(function(event){
        event.preventDefault();
        var id = $(this).parents(".js-slide-parent").attr("id");
        window.location.hash = id;
    });

    // slide down if hash exists
    // if(window.location.hash) {
    //     var hash = window.location.hash;
    //     if (hash && $(hash).length && $(".js-slide-button").length) {
    //         contentSlide($(hash));
    //     }
    //
    //     // check if hash is sublink inside module
    //     //checkSubAnchor();
    // }

    // slide toggle
    function contentSlide($object) {
        // slide object ddown and siblings up and scroll to active button
        $object.find(".js-slide-button").addClass("active");
        $object.siblings().find(".js-slide-button").removeClass("active");
        $object.siblings().find(".js-slide-content").slideUp();
        $object.find(".js-slide-content").slideToggle(function(){
            $("html").animate({scrollTop: $object.offset().top - 80},800);
        });

        // initialize carousel
        var $contentCarousel = $object.find(".js-content-carousel");

    	if ($contentCarousel.length){
			$contentCarousel.slick({
				fade: false,
				dots: false,
				infinite: true,
				arrows: true,
                autoplay: false,
                autoplaySpeed: 3500,
				speed: 1000,
				prevArrow: $object.find(".js-content-carousel-nav-prev"),
				nextArrow: $object.find(".js-content-carousel-nav-next"),
                slidesToShow: 3,
                slidesToScroll: 1,
                adaptiveHeight: true,
                responsive: [
                        {
                          breakpoint: 1200,
                          settings: {
                            slidesToShow: 2
                          }
                        },
                        {
                          breakpoint: 700,
                          settings: {
                            slidesToShow: 1
                          }
                        }
                      ]
			});
    	}

    }

    function checkSubAnchor() {
        var hash = window.location.hash,
            $section = $(hash),
            $parent = $(hash).parents(".js-slide-parent");

        if ($section.hasClass("js-slide-item")) {
            if ($parent.find(".js-slide-content").is(":hidden")) {
                    var $object = $parent;
                $object.find(".js-slide-button").addClass("active");
                $object.siblings().find(".js-slide-button").removeClass("active");
                $object.siblings().find(".js-slide-content").slideUp();
                $object.find(".js-slide-content").slideDown(function(){
                    $("html").animate({scrollTop: $section.offset().top - 80},800);
                });
            }
        }
    }

    // open on hash change
    $(window).on("hashchange load", function(e) {
        var hash = hash = window.location.hash;

        if ($(hash).hasClass("js-slide-parent")) {
            contentSlide($(hash));
        }else {
            checkSubAnchor();
        }
    });




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * fileBrowser
    // *
    // *
    $(".js-filebrowser-button").click(function(){
        $(this).parents(".js-filebrowser-item").toggleClass("active").find(".js-filebrowser").slideToggle();
    });


});
